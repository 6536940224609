<template>
    <section>
        <modal ref="publicarTienda" :titulo="`Publicar ${$config.vendedor}`" icon="online-shop" @guardar="guardar">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-12 pl-4  mt-4 my-2 text-general f-13">
                        Asignar centro de distribución
                    </div>
                    <div class="col mb-4">
                        <el-select v-model="model.idCedis" filterable size="small">
                            <el-option
                            v-for="item in cedis"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row mx-0 justify-center">
                    <div class="col-12 pl-4 text-general f-13">
                        Cupo del credito
                    </div>
                    <div class="col mb-4">
                        <template v-if="!model.ilimitado">
                            <ValidationProvider v-slot="{errors}" rules="required" name="Cupo del credito">
                                <money v-model="model.cupo" class="input-money w-100" v-bind="money" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </template>
                        <template v-else>
                            <el-input placeholder="Valor" disabled size="small" />
                        </template>
                        <el-checkbox v-model="model.ilimitado" @change="!model.ilimitado == esIlimitado()">
                            Ilimitado
                        </el-checkbox>
                    </div>
                </div>
                <div class="row mx-0 justify-center">
                    <div class="col-12 pl-4 my-2 text-general f-13">
                        Escribe el mensaje que desea enviarle al {{ $config.vendedor }}
                    </div>
                    <div class="col mb-4">
                        <ValidationProvider v-slot="{errors}" rules="required|max:500" name="observaciones">
                            <el-input v-model="model.observaciones" type="textarea" placeholder="Mensaje" class="w-100" :rows="5" maxlength="500" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>
<script>
import Leechero from '~/services/tienda/tienda'
import Cedis from "~/services/cedis";
import {Money} from 'v-money'
import moment from 'moment'
export default {
    components:{
        Money,
    },
    data(){
        return{
            cedis: [],
            tiempo_vigencia: [],
            model:{
                id: null,
                id_plan: null,
                id_tienda: null,
                tipo: 5,
                editar: true,
                comision: null,
                observaciones: null,
                cupo: 0,
                centro: '',
                idCedis: null,
                ilimitado: false,
            },
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
        }
    },
    methods: {
        toggle(info){
            this.getCedis();
            this.model.id_tienda = info.id;
            this.model.idCedis = info.id_cedis;
            this.$refs.publicarTienda.toggle();
        },
        async getCedis(){
            try {
                const {data} = await Cedis.get_cedis()
                this.cedis = data.cedis
            } catch (error){
                this.error_catch(error)
            }
        },
        async guardar(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const { data } = await Leechero.publicarTienda(this.model)
                this.notificacion('Mensaje', 'Vendedor publicado correctamente', 'success')
                await this.$store.dispatch('tiendas/info_cabecera/action_info_cabecera', data.tienda.id)
                this.$router.push({ name: 'admin.tiendas.produccion.informacion', params: { id_tienda: data.tienda.id } })
                this.$refs.publicarTienda.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        esIlimitado(){
            if(this.model.ilimitado){
                return this.model.cupo = 0;
            }
        },
    }
}
</script>
